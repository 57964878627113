//Float Label styling for forms
//see http://bradfrost.com/blog/post/float-label-pattern/

// Default Variables for Float Labels
// VFStoreConfig FORM_FLOAT_LABEL_ENABLED must be enabled for float labels to work properly

$floatLabelFontSize: $input-font-size !default;
$floatLabelTransitionProp: all !default;
$floatLabelTransitionTiming: ease-in-out !default;
$floatLabelTop: 0 !default;
$floatLabelTopAfter: $column-gutter !default;
$floatLabelActiveTop: -$column-gutter !default;
$floatLabelLeft: $column-gutter / 2 !default;
$floatLabelTextAlign: left !default;
$floatLabelColor: $input-placeholder-color !default;
$floatLabelFontWeight: $form-label-font-weight !default;
$floatLabelInputColor: null !default;
$floatLabelActiveInputBorderColor: null !default;
$floatLabelActiveColor: $form-label-color !default;
$floatLabelActiveFontSize: 12 !default;
$floatLabelActiveFontWeight: normal !default;
$floatLabelActiveAfterBG: $input-background !default;
$floatLabelInputHeight: null !default;
$floatLabelValidationErrorColor: null !default;
$floatLabelValidationErrorLabelHeight: null !default;
$floatLabelValidationErrorLabelTop: null !default;
$floatLabelValidationErrorLabelBackgroundColor: null !default;
$floatLabelErrorDivPaddingBottom: null !default;
$floatLabelErrorDivPaddingBottomMedium: null !default;
$floatLabelPromoCodeContainerPadding: null !default;
$floatLabelPromoCodeApplyButtonHeight: null !default;
$floatLabelPromoCodeApplyPaddingTop: null !default;
$floatLabelPromoCodeApplyMarginTop: null !default;
$floatLabelPromoCodeTooltipPaddingTop: null !default;
$floatLabelEmailInputMarginBottom: null !default;
$floatLabelPhoneInputMarginBottom: null !default;
$floatLabelPhoneInputWidth: null !default;
$floatLabelPhoneInputWidthMedium: null !default;
$floatLabelStateSelectErrorMarginBottom: null !default;
$floatLabelSelectPaddingTop: null !default;
$floatLabelActiveSelectPaddingTop: null !default;
$floatLabelActiveInputPaddingTop: null !default;
$floatLabelSelectPaddingLeft: null !default;
$floatLabelSelectPaddingRight: null !default;
$floatLabelSelectColor: null !default;
$floatLabelSelectValidErrorPaddingRight: null !default;
$floatLabelInputValidErrorPaddingRight: null !default;
$floatLabelFindStoreRadiusSelectWidth: null !default;
$floatLabelFindStoreZipInputWidth: null !default;
$floatLabelActiveSelectColor: null !default;
$floatLabelInputPaddingBottom: null !default;
$floatLabelSalutationFloat: null !default;
$floatLabelSalutationWidth: null !default;
$floatLabelCCSecurityCodeWidth: null !default;
$floatLabelCCSecurityCodeInputWidth: null !default;
$floatLabelFormValidationIconDisplay: block !default;
$floatLabelFormValidationIconMarginLeft: null !default;
$floatLabelFormValidationIconMarginLeftLarge: null !default;
$floatLabelFormValidationIconFontSize: null !default;
$floatLabelFormValidationIconMarginTop: null !default;
$floatLabelAddressErrorPaddingBottom: null !default;
$floatLabelGiftCardPinWidth: null !default;
$floatLabelGiftCardPinInputWidth: 100% !default;
$floatLabelGiftCardNumberWidth: null !default;
$floatLabelGiftCardNumberInputWidth: 100% !default;
$floatLabelGiftCardNumberInputMediumWidth: null !default;
$floatLabelGiftCardApplyButtonMarginTop: null !default;
$floatLabelGiftCardWhereIsThePinMarginTop: null !default;
$floatLabelOptionalStreetAddressWidth: null !default;
$floatLabelOptionalStreetAddressInputWidth: null !default;
$floatLabelZipcodeWidth: null !default;
$floatLabelZipcodeInputWidth: null !default;
$floatLabelCollectionPointZipInputWidth: null !default;
$floatLabelCollectionPointZipContainerWidthMedium: null !default;
$floatLabelCollectionPointZipContainerWidthLarge: null !default;
$floatLabelCollectionPointPickupInputWidth: null !default;
$floatLabelCollectionPointPhoneInputWidth: null !default;
$floatLabelCollectionPointInputContainerFloat: null !default;
$floatLabelCollectionPointInputContainerWidth: null !default;
$floatLabelCollectionPointInputContainerWidthMedium: null !default;
$floatLabelMyAccountInputContainerWidth: null !default;
$floatLabelMyAccountInputLeftPadding: null !default;
$floatLabelShareMyWishListLabelColor: null !default;
$floatLabelShareMyWishListLabelTop: null !default;
$floatLabelShareMyWishListLabelTopMedium: null !default;
$floatLabelWishlistContainerWidth: null !default;
$floatLabelWishlistContainerWidthMedium: null !default;
$floatLabelShareWishListOverlayLabelLeft: null !default;
$floatLabelShareWishListOverlayLabelTop: null !default;
$floatLabelShareWishListOverlayPaddingTop: null !default;
$floatLabelShareWishListOverlayLabelFontSize: null !default;
$floatLabelSignInContainerMarginTop: null !default;
$floatLabelSigninIconPaddingRight: null !default;
$floatLabelSigninIconPosition: null !default;
$floatLabelSigninIconZIndex: null !default;
$floatLabelSigninPaddingTop: null !default;
$floatLabelSignInPaddingRight: null !default;
$floatLabelSignInErrorMsgMarginBottom: null !default;
$floatLabelSigninPaddingRight: null !default;
$floatLabelSigninIconWidth: null !default;
$floatLabelSignInModalFieldWidth: null !default;
$floatLabelSignInModalFieldWidthMedium: null !default;
$floatLabelSignInModalFieldFloat: null !default;
$floatLabelSigninIconMarginLeft: null !default;
$floatLabelSigninPaddingTop: null !default;
$floatLabelProfileLabelPaddingTop: null !default;
$floatLabelProfileLabelEmailContainerWidth: null !default;
$floatLabelProfileLabelEmailContainerWidthMedium: null !default;
$floatLabelProfileNewPasswordtoggleMarginRight: null !default;
$floatLabelProfileSelectPaddingTop: null !default;
$floatLabelProfilePaddingBottom: null !default;
$floatLabelProfileIconFloatRight: null !default;
$floatLabelProfilePaddingRight: null !default;
$floatLabelProfilePosition: null !default;
$floatLabelProfileMarginRight: null !default;
$floatLabelProfileInputBoxMarginRight: null !default;
$floatLabelProfilePaddingLeft: null !default;
$floatLabelProfileGenderFloat: null !default;
$floatLabelProfileGenderDroDownMarginLeft: null !default;
$floatLabelProfileGenderMarginRight: null !default;
$floatLabelProfileGenderPosition: null !default;
$floatLabelProfilePaddingTop: null !default;
$floatLabelInputMarginBottom: null !default;
$floatLabelContainerwidthMedium: null !default;
$floatLabelContainerwidth: null !default;
$floatLabelInputErrorPaddingTop: null !default;
$floatLabelReturnOrderNumberInputWidth: null !default;
$floatLabelReturnOrderLastNameInputWidth: null !default;
$floatLabelReturnWhereToFindTooltipDisplay: null !default;
$floatLabelReturnWhereToFindTooltipMarginBottom: null !default;
$floatLabelReturnWhereToFindTooltipMarginLeft: null !default;
$floatLabelReturnWhereToFindErrorMarginBottom: null !default;
$floatLabelOrderNumberLabelTop: null !default;
$floatLabelActiveOrderNumberLabelTop: null !default;
$floatLabelRegistrationNewPasswordToggleMarginRight: null !default;
$floatLabelRegistrationNewPasswordToggleMarginTop: null !default;
$floatLabelRegistrationClear: null !default;
$floatLabelFormValidationIconPaddingTop: null !default;
$floatLabelRegistrationContainerPaddingRight: null !default;
$floatLabelFormValidationIconMarginRight: null !default;
$floatLabelFormValidationIconMarginRightWithTooltip: null !default;
$floatLabelOrderLookupPaddingTop: null !default;
$floatLabelOrderNumberContainerPaddingTop: null !default;
$floatLabelRegistrationPaddingLeft: null !default;
$floatLabelOrderNumberClearBoth: null !default;
$floatLabelLoyaltyIconFloatRight: null !default;
$floatLabelInputMobilePhoneFlagPosition: null !default;
$floatLabelMobilePhoneFlagContainerDisplay: null !default;
$floatLabelMobilePhoneFlagContainerLeft: null !default;
$floatLabelMobilePhoneFlagContainerPosition: null !default;
$floatLabelMobilePhoneFlagContainerTop: null !default;
$floatLabelMobilePhoneFlagContainerWidth: null !default;
$floatLabelInputMobilePhoneFlagImageMargin: null !default;
$floatLabelInputMobilePhoneFlagImageWidth: null !default;
$floatLabelInputMobilePhoneFlagLabelFontSize: null !default;
$floatLabelInputMobilePhoneInputBoxMarginBottom: null !default;
$floatLabelInputMobilePhoneFocusPaddingLeft: null !default;
$floatLabelInputMobilePhonePlaceHolderPaddingLeft: null !default;
$floatLabelInputMobilePhoneHelpTextMarginBottom: null !default;
$floatLabelInputMobilePhoneFloatLabelDisplay: null !default;
$order-number-container-input-width: null !default;
$order-number-container-input-float: null !default;
$order-number-container-input-text-align-last: null !default;

@mixin vfdp-float-label {

  .float-label-container {
    position: relative;

    &.inline-block {
      display: inline-block;
    }

    div.error {
      clear: left;
      margin-bottom: 5px;
      padding-bottom: $floatLabelErrorDivPaddingBottom;

      @include breakpoint(medium) {
        padding-bottom: $floatLabelErrorDivPaddingBottomMedium;
      }
    }

    input {
      color: $floatLabelInputColor;
      float: left;
      height: $floatLabelInputHeight;
      padding-bottom: $floatLabelInputPaddingBottom;

      &.find-a-store-zip-input {
        width: $floatLabelFindStoreZipInputWidth;
      }

      //Returns Order Number Input
      &.return-order-number {
        width: $floatLabelReturnOrderNumberInputWidth;
      }

      &.return-gift-last-name {
        width: $floatLabelReturnOrderLastNameInputWidth;
      }

      &.valid,
      &.error {
        padding-right: $floatLabelInputValidErrorPaddingRight;
      }
    }

    select {
      color: $floatLabelSelectColor;
      height: $floatLabelInputHeight;
      padding-bottom: $floatLabelInputPaddingBottom;
      padding-left: $floatLabelSelectPaddingLeft;
      padding-right: $floatLabelSelectPaddingRight;
      padding-top: $floatLabelSelectPaddingTop;

      &.find-a-store-radius-select {
        float: left;
        width: $floatLabelFindStoreRadiusSelectWidth;
      }

      &.valid,
      &.error {
        padding-right: $floatLabelSelectValidErrorPaddingRight;
      }

    }

    label {
      color: $floatLabelColor;
      font-size: $floatLabelFontSize;
      font-style: normal;
      font-weight: $floatLabelFontWeight;
      height: get-form-input-height();
      left: $floatLabelLeft;
      line-height: get-form-input-height();
      pointer-events: none;
      position: absolute;
      text-align: $floatLabelTextAlign;
      top: $floatLabelTop;
      transition-property: $floatLabelTransitionProp;
      transition-timing-function: $floatLabelTransitionTiming;
      z-index: 1;

      &.float-label-select {
        opacity: 0;
      }

      &.return-gift-order-number-label {
        top: $floatLabelOrderNumberLabelTop;
      }

      &.validation-error {
        color: $floatLabelValidationErrorColor;
      }
    }

    &.float-label-active {
      input,
      select {
        border-color: $floatLabelActiveInputBorderColor;
      }

      input {
        padding-top: $floatLabelActiveInputPaddingTop;
      }

      select {
        color: $floatLabelActiveSelectColor;
        padding-top: $floatLabelActiveSelectPaddingTop;
      }

      label {
          color: $floatLabelActiveColor;
          font-size: rem-calc($floatLabelActiveFontSize);
          font-weight: $floatLabelActiveFontWeight;
          top: $floatLabelActiveTop;
          width: auto;

          &.float-label-select {
            opacity: 1;
          }

          &::after {
            background: $floatLabelActiveAfterBG;
            content: '';
            height: get-border-value($input-border, 'width');
            left: 0;
            position: absolute;
            right: 0;
            top: $floatLabelTopAfter;
            z-index: -1;
        }

        &.return-gift-order-number-label {
          top: $floatLabelActiveOrderNumberLabelTop;
        }

        &.validation-error {
          color: $floatLabelValidationErrorColor;

          &::after {
            background-color: $floatLabelValidationErrorLabelBackgroundColor;
            height: $floatLabelValidationErrorLabelHeight;
            top: $floatLabelValidationErrorLabelTop;
          }
        }
      }
    }

    &.input-set-wrap {
      //Promo Code Form
      &.promo-code-form-billing-promo-code-container {
        padding: $floatLabelPromoCodeContainerPadding;

        + .billing-promo-apply {
          margin-top: $floatLabelPromoCodeApplyMarginTop;
          padding-top: $floatLabelPromoCodeApplyPaddingTop;

          .button.secondary {
            height: $floatLabelPromoCodeApplyButtonHeight;
          }

          + .billing-promo-where {
            padding-top: $floatLabelPromoCodeTooltipPaddingTop;
          }
        }
      }


      //My Account - Returns
      &.return-order-number-box {
        .return-where-to-find {
          display:  $floatLabelReturnWhereToFindTooltipDisplay;
          margin-bottom: $floatLabelReturnWhereToFindTooltipMarginBottom;
          margin-left: $floatLabelReturnWhereToFindTooltipMarginLeft;
        }

        div.error {
          margin-bottom: $floatLabelReturnWhereToFindErrorMarginBottom;
        }
     }

      //Address Form
      .address-form-inline-reason {
        &.small {
          display: none;
        }

        &.medium-up {
          clear: both !important; // sass-lint:disable-line no-important
          display: block;
        }
      }

      &.address-form {
        &-state,
        &-country {
            select {
                &.error {
                    margin-bottom: $floatLabelStateSelectErrorMarginBottom;
                }
            }
        }

        &-email {
          .address-form-email-input {
            margin-bottom: $floatLabelEmailInputMarginBottom;
          }
        }

        &-phone {
          .input-box {
            margin-bottom: $floatLabelPhoneInputMarginBottom;
            width: $floatLabelPhoneInputWidth;

            @include breakpoint(medium) {
              width: $floatLabelPhoneInputWidthMedium;
            }
          }
        }
      }

      &.street-name-box-2 {
        width: $floatLabelOptionalStreetAddressWidth;

        .address-form-street-name-input {
          width: $floatLabelOptionalStreetAddressInputWidth;
        }
    }

    &.zipcode-box {
        width: $floatLabelZipcodeWidth;

        .zipcode {
            width: $floatLabelZipcodeInputWidth;
        }
    }

      //Credit Card Form
      &.security-code-box {
        width: $floatLabelCCSecurityCodeWidth;

        .security-code-input {
            width: $floatLabelCCSecurityCodeInputWidth;
        }
      }
    }

    &.address-form-title {
      .input-title-class {
        float: $floatLabelSalutationFloat;
        width: $floatLabelSalutationWidth;
      }
    }
  }

  //Ship to store
  .shipping .ship-to-store-form .find-a-store-form {
    .float-label-container + .ship-to-store-find {
      margin-top: 0;
    }
  }

  //Collection Point for EU
  .collection-point-address-form {
    .float-label-container {
      &.collection-point-returns-selected-store-drop-off-address-form-container {
        float: $floatLabelCollectionPointInputContainerFloat;
        width: $floatLabelCollectionPointInputContainerWidth;

        @include breakpoint(medium) {
          width: $floatLabelCollectionPointInputContainerWidthMedium;
        }

        input {
          width: $floatLabelCollectionPointPickupInputWidth;
        }

        #selectedstore-phone {
          width: $floatLabelCollectionPointPhoneInputWidth;
        }
      }
    }
  }

  .terms-checkbox-container {
    clear: left;
  }

  //Collection Point Postal Code Search
  .collection-point-find {
    #collectionPointZip {
      width: $floatLabelCollectionPointZipInputWidth;
    }

    .float-label-container {
      @include breakpoint(medium) {
        width: $floatLabelCollectionPointZipContainerWidthMedium;
      }

      @include breakpoint(large) {
        width: $floatLabelCollectionPointZipContainerWidthLarge;
      }
    }
  }

  //Gift Card
  .gift-card-form .billing-gift-card-row {
    .float-label-container {
       //Gift Card
       &.billing-gift-card-pin {
        width: $floatLabelGiftCardPinWidth;

        .input-box {
          width: $floatLabelGiftCardPinInputWidth;
        }
      }

      &.billing-gift-card-number {
        width: $floatLabelGiftCardNumberWidth;
      }

      .billing-gift-card-number-input {
        width: $floatLabelGiftCardNumberInputWidth;

        @include breakpoint(medium) {
          width: $floatLabelGiftCardNumberInputMediumWidth;
        }
      }


      +.billing-gift-card-apply-button-container.input-set-wrap {
        .billing-gift-card-action-apply {
          margin-top: $floatLabelGiftCardApplyButtonMarginTop;
        }

        +.billing-gift-card-check {
          margin-top: $floatLabelGiftCardWhereIsThePinMarginTop;
        }
      }
    }
  }

  // My Account Address Form
  #myaccount .float-label-container {
      width: $floatLabelMyAccountInputContainerWidth;

      input {
          padding-left: $floatLabelMyAccountInputLeftPadding;
          width: $floatLabelMyAccountInputContainerWidth;
      }

      select {
          padding-left: $floatLabelMyAccountInputLeftPadding;
          width: $floatLabelMyAccountInputContainerWidth;
      }
  }

  // My Account share your wishlist
  .reveal-overlay #shareMyWishlistURLModel {
    width: $floatLabelWishlistContainerWidth;

    .float-label-text-area {
      color: $floatLabelShareMyWishListLabelColor;
      font-size: rem-calc($floatLabelShareWishListOverlayLabelFontSize);
      left: $floatLabelShareWishListOverlayLabelLeft;
      top: $floatLabelShareMyWishListLabelTop;

      @include breakpoint(medium) {
        top: $floatLabelShareMyWishListLabelTopMedium;
      }
    }

    .share-link {
      overflow: hidden;
      padding-top: $floatLabelShareWishListOverlayPaddingTop;
    }

    @include breakpoint(medium) {
      width: $floatLabelWishlistContainerWidthMedium;
    }
  }


  // Checkout Login Modal.
  #signin,
  #modal-checkout-login-register {
    .account-entry-container,
    .account-entry-checkout-container {
      .float-label-container {
        padding-right: $floatLabelSignInPaddingRight;

        &.float-label-active {
          label {
            margin-top: $floatLabelSignInContainerMarginTop;
          }

          input {
            &.error,
            &.valid {
              padding-top: $floatLabelInputErrorPaddingTop;
            }
          }
        }

        .myaccount-signin-email-input,
        .myaccount-signin-password-input {
          float: $floatLabelSignInModalFieldFloat;
          width: $floatLabelSignInModalFieldWidth;

          @include breakpoint(medium) {
            width: $floatLabelSignInModalFieldWidthMedium;
          }
         }

        .form-success-icon,
        .form-error-icon {
          margin-left: $floatLabelSigninIconMarginLeft;
          padding-top: $floatLabelSigninPaddingTop;

          &.valid {
            padding-right: $floatLabelSigninIconPaddingRight;
            position: $floatLabelSigninIconPosition;
            z-index: $floatLabelSigninIconZIndex;
          }
        }

        div.error {
          &.valid {
            margin-bottom: $floatLabelSignInErrorMsgMarginBottom;
          }
        }

        label {
          padding-top: $floatLabelSigninPaddingTop;
        }
      }
    }
  }

  #registration {
    .myaccount-registration-section,
    .welcome-account-entry-myaccount-section {
      .float-label-container {
        padding-right: $floatLabelRegistrationContainerPaddingRight;

        &.float-label-active {
          label {
            margin-top: $floatLabelSignInContainerMarginTop;
          }

          input {
            &.error,
            &.valid {
              padding-top: $floatLabelInputErrorPaddingTop;
            }
          }
        }

        .single-pass-input-show-toggle {
          margin-right: $floatLabelRegistrationNewPasswordToggleMarginRight;
          margin-top: $floatLabelRegistrationNewPasswordToggleMarginTop;
        }

        .myaccount-registration-last-name-label,
        .myaccount-registration-password-label {
          padding-left: $floatLabelRegistrationPaddingLeft;
        }
      }

      .reg-confirm-password,
      .reg-email,
      .reg-first-name {
         clear: $floatLabelRegistrationClear;
      }
    }
  }

  // My Account profile
  .myaccount-profile .myaccount-profile-first-section {
    #myaccount-userinfo,
    #myaccount-password {
      .myaccount-profile-reg-gender-container {
        &.float-label-container {
          .form-success-icon {
            float: $floatLabelProfileGenderFloat;
            margin-left: $floatLabelProfileGenderDroDownMarginLeft;
            padding-right: $floatLabelProfileGenderMarginRight;
            position: $floatLabelProfileGenderPosition;
          }
        }
      }

      .float-label-container {
        &.float-label-active {
          label {
            padding-top: $floatLabelProfileLabelPaddingTop;
          }

          select {
            padding-top: $floatLabelProfileSelectPaddingTop;
          }
        }

        .form-success-icon,
        .form-error-icon {
          &.valid {
            float: $floatLabelProfileIconFloatRight;
            margin-right: $floatLabelProfileMarginRight;
            padding-right: $floatLabelProfilePaddingRight;
            padding-top: $floatLabelProfilePaddingTop;
            position: $floatLabelProfilePosition;
          }
        }

        .single-pass-input-show-toggle {
          margin-right: $floatLabelProfileNewPasswordtoggleMarginRight;
        }

        input {
          margin-right: $floatLabelProfileInputBoxMarginRight;
          padding-bottom: $floatLabelProfilePaddingBottom;

          &.error {
            margin-bottom: $floatLabelInputMarginBottom;
          }
        }

        label {
          &.validation-error {
            padding-left: $floatLabelProfilePaddingLeft;
          }
        }
        width: $floatLabelContainerwidth;
        @include breakpoint(medium) {
          width: $floatLabelContainerwidthMedium;
        }
      }
    }
  }

  #account,
  #cmsAccountModal {
    .non-member-rewards {
      .myaccount-registration-gender-container {
        &.float-label-container {

          .form-success-icon,
          .form-error-icon {
            &.valid {
              float: $floatLabelLoyaltyIconFloatRight;
            }
          }
        }
      }
    }
  }

  #order-lookup,
  #order-lookup-signin {
    section .float-label-container {
      .order-number-label {
        padding-top: $floatLabelOrderLookupPaddingTop;
      }
    }

    .order-number-container {
      clear: $floatLabelOrderNumberClearBoth;
      padding-top: $floatLabelOrderNumberContainerPaddingTop;
      input {
        float: $order-number-container-input-float;
        width: $order-number-container-input-width;
        text-align-last: $order-number-container-input-text-align-last;
      }
    }
  }

  //TODO: Clean this up in VFDP-64132 and/or VFDP-67405
  form {
    // Exclude forms that use Foundation Abide
    // Exclude forms with a data attribute flag
    &:not([data-abide]):not([data-vfdp-novalidate]) {
      .float-label-container {
        //form validation
        .form-success-icon,
        .form-error-icon {
            &.valid {
                display: $floatLabelFormValidationIconDisplay;
                float: left;
                margin-left: $floatLabelFormValidationIconMarginLeft;
                margin-right: $floatLabelFormValidationIconMarginRight;
                padding-top: $floatLabelFormValidationIconPaddingTop;
                width: auto;

                @include breakpoint(large) {
                    margin-left: $floatLabelFormValidationIconMarginLeftLarge;
                }

                &::before {
                  font-size: $floatLabelFormValidationIconFontSize;
                  margin-top: $floatLabelFormValidationIconMarginTop;
                }
            }
        }

        label {
          width: auto;
        }

        .error.valid {
          span {
              padding-bottom: $floatLabelAddressErrorPaddingBottom;
          }
        }

        &.security-code-box {
          .form-success-icon,
          .form-error-icon {
            &.valid {
              margin-right: $floatLabelFormValidationIconMarginRightWithTooltip;
            }
          }
        }
      }

      .saved-address-info {
        float: none;
      }

      .credit-card-images {
        margin-bottom: 10px;
      }
    }
  }

  .country-phone-flag{
    position: $floatLabelInputMobilePhoneFlagPosition;
    
      .country-phone-flag-image-wrapper-container{
          display: $floatLabelMobilePhoneFlagContainerDisplay;
          left: $floatLabelMobilePhoneFlagContainerLeft;
          position: $floatLabelMobilePhoneFlagContainerPosition;
          top: $floatLabelMobilePhoneFlagContainerTop;
          width: $floatLabelMobilePhoneFlagContainerWidth;
          
              .country-phone-flag-image{
                  margin: $floatLabelInputMobilePhoneFlagImageMargin;
                  width: $floatLabelInputMobilePhoneFlagImageWidth;
              }

              .country-phone-flag-label{
                  font-size: $floatLabelInputMobilePhoneFlagLabelFontSize;
                  
              }
      }
   
      >.input-box{
           margin-bottom: $floatLabelInputMobilePhoneInputBoxMarginBottom;
          
            &:focus{ 
                padding-left: $floatLabelInputMobilePhoneFocusPaddingLeft;
            }

            &:not(:placeholder-shown){ 
               padding-left: $floatLabelInputMobilePhonePlaceHolderPaddingLeft;
            }
      }  

      .loyalty-program-enrollment-mobile-text{
            margin-bottom: $floatLabelInputMobilePhoneHelpTextMarginBottom;
      }

      &.float-label-active {
          .country-phone-flag-image-wrapper-container{
              display: $floatLabelInputMobilePhoneFloatLabelDisplay;
          }
      }
  }  
}
